.card-acao {
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.card-acao:hover {
  background-color: #efefef;
  cursor: pointer;
}

.card-acao.selected {
  background-color: rgba(0, 51, 153, 0.2);
}

.card-acao > p {
  color: black;
  font-size: 1rem;
}

.card-acao > .card-codigo {
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 200;
  color: #6b6666 !important;

  display: block;
  margin-left: auto;
}
