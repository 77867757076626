.container-form-search-samples {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 20px 0;
    padding: 15px 0;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #CDCDCD;
    text-align: center;
}

.label-search-samples {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 10px;
}

.span-search-samples {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

#button-search-samples {
    font-size: 30px;
    background-color: transparent;
    color: #003399;
    transition: opacity 0.2s;
    border-width: 0;
}

#button-search-samples:hover {
    opacity: 0.7;
}

.container-result-search-samples {
    display: grid;
    grid-template-columns: auto auto;
}

.label-left-result-search-samples {
    text-align: end;
    font-size: 16px;
    padding: 5px 20px;
}

.label-right-result-search-samples {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 20px;

}