main.editor-portal-medico-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  max-width: 1200px;
}

.editor-top-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: 1.5rem;
}

.editor-top-container-buttons {
  display: flex;
  align-items: center;
}

.shadow-md {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.editor-card {
  background-color: white;
  border-radius: 4px;

  padding: 1.5rem;
}

.areas-title {
  font-size: 1.2rem;
  font-weight: 500;

  margin-bottom: 1.5rem;
}

.areas-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.add-area-button {
  display: flex;
  align-items: center;

  margin-left: 0.5rem;

  cursor: pointer;
}

.add-area-button > p {
  font-size: 1.1rem;
  color: #003399;
  font-weight: 500;
}
