.image-placeholder {
  width: 100px;
  height: 100px;

  background-color: #cecece;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  cursor: pointer;
}

.image-input {
  object-fit: cover;
  width: 100px;
  height: 100px;
}
