#alert-dialog-title {
    text-align: center;
    margin: 20px;
}

#button-dialog-aviso {
    display: flex;
    background: transparent;
    width: 80%;
    max-width: 300px;
    height: 40px;
    border-width: 1px;
    border-color: #003399;
    margin: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 4px;
}

#button-dialog-aviso:hover {
    border-width: 2px;
    background:#F2F2F2;
}