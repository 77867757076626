.papel-wrapper {
  position: relative;

  margin-bottom: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
}

.papel-wrapper.selected {
  background-color: rgba(0, 51, 153, 0.2);
}

.papel-wrapper:hover {
  background-color: #efefef;
}

.papel-wrapper > * {
  color: black;
}

.papel-checkbox {
  position: absolute !important;
  top: 0;
  right: 0;

  margin-top: 8px !important;
}

.papel-nome {
  font-size: 1.1rem;
  font-weight: bold;

  margin-bottom: 0.5rem;
}
