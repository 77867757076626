.grid-container-Dom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 4px;
  padding: 12px 30px;
  flex-grow: 1px;
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
}
.PaperMapas {
  height: 100%;
  padding: 10px;
}
.PaperDapas {
  padding: 20px;
}

#container-aba-ctrl {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-aba-ctrl {
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  color: #cdcdcd;
  transition: all 0.3s;
}

.button-aba-ctrl:hover {
  background-color: #eeeeee;
  color: #000;
}
.container-table {
  margin-top: 16px;
}

.container-table-bottom{
  margin-top: 16px;
  margin-bottom: 50px;
}

.table {
  width: 100%;
  border: 1px rgba(224, 224, 224, 1) solid;
  border-radius: 4px;
}
