.controle-form-wrapper {
  width: 95%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  color: black;

  position: relative;
}

.wrapper-topo {
  margin-bottom: 1.5rem;
  padding: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper-filtros {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.wrapper-datas span {
  color: rgba(0, 0, 0, 0.54);
}

.MuiPickersBasePicker-container span {
  color: black;
}
.MuiPickersDay-daySelected span {
  color: white;
}

.MuiPickersCalendarHeader-switchHeader p {
  color: black;
}

.MuiDialogActions-root button {
  /* background-color: #003399; */
}

.red-span {
  color: #ed3237;
  font-weight: bold;
  font-size: 18px;
  align-self: center;

  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 95%;
  max-width: 900px;

  padding: 1.5rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: white;
}

.modal-body-inner {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body h2 {
  font-size: 25px;

  margin-bottom: 2rem;
}

.modal-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: 1.5rem;
}

.table-container {
  margin-top: 1rem;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.dialog-list-item {
  cursor: pointer;
}

.dialog-list-item:hover {
  background-color: #f5f5f5;
}
