main {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px;
}

main ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  list-style: none;
}
.selecionar_visao {
  display: flex;
  justify-content: center;
  align-items: center;
}
#app {
  flex: 1;
  width: 1200;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#adicionarSVG {
  height: 50px;
  width: 50px;
  float: right;
  margin-right: 40px;
  cursor: pointer;
}

#unidade {
  display: flex;
  margin-top: 10px;
}

#unidadeNome {
  margin-right: 10px;
  margin-left: 10px;
}

#unidadeOpen {
  cursor: pointer;
}

#buttonAddExame{
  margin: 0.5rem;
}
