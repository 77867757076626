.modal_editar{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    padding: 2rem;
}
.modal_horaios{
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    padding: 2rem;
}

@media screen and (max-width: 600px) {
    .modal_editar {
        width: 95vw;
    }
    .modal-title {
        font-size: 20px;
    }
}