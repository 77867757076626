.form-container-msg {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    margin-top: 20px;
}

.grid-container-msg {
    display: grid;
    grid-template-columns: auto;
    padding-left: 20px;
    padding-right: 20px;
    flex-grow: 1px;
    background-color: #fff;
    margin-top: 20px;
}

.grid-item-msg {
    margin-top: 10px;
}

.btnPadrao {
    background-color: #003399;
    color: #FFF;
    padding: 4px 10px;
    border-radius: 4px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.btnPadrao:hover {
    background-color: navy;
}

#btnGreen {
    background-color: Green;
}
#btnGreen:hover {
    background-color: #006600;
}

#btnRed {
    background-color: Red;
}
#btnRed:hover {
    background-color: #990000;
}

.table-container-msg {
    margin-top: 20px;
}

.table-msg {
    width: 100%;
    border-collapse: collapse;
    border:1px rgba(224, 224, 224, 1) solid;
    border-radius: 4px;
}

.table-msg th {
    background-color:#FFF;
    border: #FFF;
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}
 
.table-msg tr:nth-child(even) {
    background-color:#FFF; 
} 

.table-msg tr:nth-child(odd) {
    background-color:#F0F0F5;
}

.table-msg td {
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}

.corpoMensagem {
    margin-top: 10px;
    margin-bottom: 6px;
}

#txtCorpoMsg {
    color: #000000;
    font-weight: bold;
}

.btnOpcao {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: none;
    color: #003399;
    font-size: 16px;
    cursor: pointer;
}

#text-area-message {
    display: flex;
    width: 100%;
}

#content-message-title {
    font-size: 18px;
    font-weight: bold;
}

#content-message-body {
    font-size: 18px;
    color:#000000;
}

.container-label-dialog-message-app {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  #container-status-table-message-app {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .label-dialog-message-app {
    font-size: 15px;
    padding: 1px 0;
  }

  #label-dialog-message-app-right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  #label-dialog-tittle-message-app {
    font-size: 16px;
    font-weight: bold;
    padding: 1px 0 4px 0;
  }

  #container-table-dialog-message-app {
    display: grid;
    grid-template-columns: auto auto;
  }

  #label1-table-dialog-message-app {
      width: 140px;
      color: #000;
      font-weight: bold;
      padding: 5px 10px;
  }

  #label2-table-dialog-message-app {
      text-align: center;
      width: 120px;
      color: #000;
      padding: 5px 10px;
  }

  #container-button-accordeon-message-app {
    display: flex;
    flex-direction: column;
    padding: 10px;
    grid-column: 1 / 3;
  }

  .btn-message-app-check-default {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid #000;
    background-color: #CEE3F6;
    transition: opacity 0.2s;
    margin: 2px 0px 2px 15px;
  }

  .btn-message-app-check-default:hover {
    opacity: 0.7;
  }