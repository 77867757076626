.grid-container-Dom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 4px;
    padding: 12px 30px;
    flex-grow: 1px;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #CDCDCD;
}

.grid-container-Cli {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
    flex-grow: 1px;
    background-color: #fff;
    margin-bottom: 10px;
}

.grid-container-Cli2 {
    display: grid;
    margin-left: 30px;
    padding-left: 20px;
    padding-right: 20px;
    flex-grow: 1px;
    background-color: #fff;
    margin-bottom: 10px;
}

.grid-item-Cli {
    margin-top: 10px;
}

#grid-container-cli-tittle {
    text-align: center;
}

.grid-item-Cli-2 {
    margin-top: 10px;
    grid-column: 1 / 3;
}

.espacoso {
    width: 100%;
}

div #rua {
    width: 200px;
}

.grid-item-Btn {
    margin-top: 10px;
}

#divMaps {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

#btnMaps {
    margin-top: 10px;
    align-items: center;
}

#btnMaps span {
    color: #003399;
}

#btnInfoCliente {
    display: flex;
    justify-content: center;
}

.containerBtnDialogs {
    display: flex;
    justify-content: center;
    justify-items: center;
}

.btnDialogs {
    background-color: #003399;
    color: #FFF;
    padding: 4px 4px;
    border-radius: 4px;
    width: 100px;
}

.btnDialogs:hover {
    background-color: navy;
}

div #containerObservacoes{
    padding: 10px;
    min-width: 200px;
}

.opList {
    align-self: center;
}

.selectForm-coleta {
    margin: 4px;
    width: 180px;
}

#header-information-client {
    align-items: center;
    display: flex;
    background-color: #003399;
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 12px;
    justify-content: space-between;
}

#btn-close-info-client {
    background-color: transparent;
    border: none;
}

#icon-info-client {
    font-size: 24px;
}

#icon-back-info-client {
    font-size: 24px;
    color: #FFF;
}

@media (max-width: 495px) {

    #header-information-client {
        font-size: 16px;
    }

    #icon-info-client {
        font-size: 18px;
    }
    
    #icon-back-info-client {
        font-size: 18px;
    }

    .grid-item {
        width: 100%;
    }

    .selectForm-coleta {
        width: 100%;
    }

    .grid-container-Cli {
        padding: 0;
        padding-left: 4px;
        grid-template-columns: auto;
    }

    .grid-item-Cli #nome,
    .grid-item-Cli-2 #nome{
        min-width: 160px;
        font-weight: bold;
        font-size: 14px;;
    }

    .grid-item-Cli,
    .grid-item-Cli-2 {
        margin-top: 2px;
        margin-left: 2px;
        grid-column: 1;
    }

    .grid-item-nome-Dom {
        margin-right: 8px;
        margin-left: 8px;
    }

    .grid-item-Btn {
        display: flex;
        flex: 1px;
        justify-content: flex-end;
    }

    .container-interval-data {
        justify-content: center;
    }
}