.container-formulario {
    display: flex;
    flex-direction: column; 
}

.aba-titulo {
    display: flex;
    margin-top: 20px;
    background-color: #003399;
    padding: 4px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    color: #FFF;
    font-weight: bold;
    font-size: 1.2rem;
    justify-content: center;
}

.grid-container {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 12px 20px;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #CDCDCD;
}

.grid-item-topo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1px;
    margin-bottom: 15px;
}

.grid-item-dataHora {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.grid-item-selectHora{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 0;
}

.grid-item-pedido {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1px;
    margin-bottom: 15px;
}

.grid-item-cliente {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 10px;
}

#nomeHora {
    font-size: 12px;
}

.containerFormulario {
    display: flex;
    flex-direction: column;
}

.tituloFormulario {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

#codPedido {
    width: 150px;
}

.btnAgendar {
    background-color: #003399;
    color: #FFF;
    padding: 4px 10px;
    border-radius: 4px;
    margin-top: 12px;
}

.btnAgendar:hover {
    background-color: navy;
}

#campoData {
    float: left;
    margin-left: 20px;
    margin-top: 20px;
}

#campoHora {
    float: left;
    margin-left: 100px;
    margin-top: 24px;
}

.grid-item .nomeHora {
    font-size: 12px;
}

#campoCodigo {
    display: flex;
    float: left;
    margin-left: 20px;
    margin-top: 30px;
}

#campoNome {
    display: flex;
    flex-direction: column;
    float: left;
    margin-left: 40px;
    margin-top: 25px;
}

#nomeCliente {
    font-size: 12px;
    margin-top: 10px;
}

#nome {
    min-width: 160px;
    font-weight: bold;
    margin-top: 10px;
}

#botao {
    float: right;
    margin-right: 10px;
    margin-top: 50px;
}

#itemLista {
    font-size: 16px;
    color: #000;
    margin-left: 30px;
}

#gridData {
    margin-top: 20px;
    margin-left: 30px;
}

#gridHora {
    margin-top: 24px;
    margin-left: 30px;
    margin-bottom: 20px;
}

#gridHora .nomeHora {
    font-size: 12px;
}

@media (max-width: 424px) {
    .grid-item-selectHora{
        margin-left: 0;
        margin-top: 10px;
    }
}
   