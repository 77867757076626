.pill {
  background-color: #003399;
  padding: 0.5rem;

  border-radius: 3px;

  display: inline-flex;
  align-items: center;
}

.pill > p {
  font-size: 1rem;

  margin-right: 10px;
}
