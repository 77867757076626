.cadastro-formulario-wrapper {
  width: 95%;
  max-width: 1000px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;

  position: relative;
}

.dados-paciente-wrapper {
  margin-top: 1rem;
  padding: 1.5rem;
}

.dados-paciente-inner {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.dados-paciente-inner p {
  color: black;
  margin-bottom: 15px;
}

.link-copy {
  width: 100%;
  margin-bottom: 1rem !important;
}

@media (min-width: 600px) {
  .link-copy {
    width: 60%;
    margin-bottom: 0 !important;
  }
}
