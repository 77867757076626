.input-container {
  border: none;
  outline: none;
}

.input {
  outline: none;
  border: 1px solid #cecece;
  border-radius: 6px;

  font-size: 1.1rem;

  padding: 0.75rem 1rem;

  width: 100%;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input:focus {
  border: 2px solid #003399;
}
