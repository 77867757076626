#paper{
    margin: 35px;
}



#submit-button{
    margin: 35px;
    display: flex;
    
}

.MuiGrid-root .MuiGrid-grid-md-4{
    margin-left: 15px;
}

/* MuiGrid-item MuiGrid-grid-md-4 */