.pesquisa{
    background: none;
    width: 80%;
}

@media only screen and (max-width: 600px){
    .pesquisa{
        background: none;
        width: 100%;
    }
}