.container-information-unit {
    display: flex;
    flex-direction: column;
}

.content-information-unit {
    padding: 10px;
    margin: 0 20px;
}

#textarea-information-unit {
    width: 100%;
}

.content-information-unit-short {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.content-information-unit-image {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

#img-unidade-foto {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

#btn-unidade-foto {
    text-align: center;
    position: absolute;
    margin-top: 150px;
    height: 35px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    width: 100px;
    transition: background-color 0.2s;
    font-size: 18px;
    padding-top: 5px;
    cursor: pointer;
}

#btn-unidade-foto:hover {
    background-color: #CDCDCD;
    color: blue;
    border-color: blue;
}

#btn-cancel-update-photo {
    position: absolute;
    margin-top: 150px;
    height: 35px;
    background-color: rgb(202, 159, 159);
    border: 1px solid black;
    border-radius: 4px;
    width: 100px;
    transition: opacity 0.2s;
    font-size: 18px;
    cursor: pointer;
}

#btn-cancel-update-photo:hover {
    opacity: 0.7;
    color: darkblue;
    border-color: darkblue;
}

.pesquisa {
    display: flex;
    width: 75%;
    background-color: #fff;
  }

