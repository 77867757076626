.container-central-faturamento  {
    display: flex;
    width: 98%;
    flex-direction: column;
    margin: 0 auto;
}

.container-abas-faturamento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.5rem 0 0 0;
}

@media print {
    .container-central-faturamento  {
        max-Width: 95%;
    }

    @page{
        size: landscape;
    }

}

