.app-popover-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  padding: 1.9rem;

  cursor: pointer;
}

.app-popover-button:hover {
  background-color: rgba(202, 202, 205, 0.6);
  transition: all 0.2s ease-out;
}
.app-popover-button > .icon {
  
  width: 30px;
  font-size: 30px;
  color: #962112;
  margin-bottom: 0.5rem;

  align-self: center;
}

.app-popover-button > span {
  font-weight: 500;
  color: #003399;
  padding-top: 10px;

  text-align: center;
}
