.BarAmostras {
  margin-top: 0.8rem;
}

.BarMapas {
  margin-top: 0.8rem;
}

.PaperMapas {
  height: 20rem;
}

.PaperAmostras {
  height: 20rem;
}
.BarTipoPorCasos {
  margin-top: 0.8rem;
}
.PaperTipoPorCasos {
  height: 20rem;
}

.PaperPositivoPorPlano {
  margin-top: 0.8rem;
}
.PaperPositivoPorPlano {
  height: 20rem;
}

.BarAmostraStatus {
  margin-top: 0.8rem;
}
.PaperAmostraStatus {
  height: 20rem;
}

@media (max-width: 600px) {
  .PaperMapas {
    height: 17rem;
  }

  .PaperAmostras {
    height: 17rem;
  }

  .PaperTipoPorCasos {
    height: 17rem;
  }
  
  .PaperPositivoPorPlano {
    height: 17rem;
  }
}
