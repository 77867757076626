.container-noticias {
  width: 95%;
  max-width: 1300px;

  margin: 2rem auto;
  margin-bottom: 2rem;

  padding: 1rem;

  display: grid;
  justify-content: center;
  align-items: stretch;

  grid-template-columns: repeat(auto-fit, minmax(250px, 370px));
  column-gap: 3rem;
  row-gap: 2rem;
}
