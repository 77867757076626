.option {
  cursor: pointer;

  border-radius: 4px;
  padding: 1rem 0.5rem;

  display: flex;
  align-items: center;
}

.option:hover {
  background-color: #e5e5e5;
}

.option > .option-text {
  margin-left: 0.5rem;
}

.option > .option-text > h2 {
  margin-bottom: 0.25rem;
}

.option > .option-text > span {
  font-style: italic;
  font-size: 1rem;
  font-weight: 200;
}
