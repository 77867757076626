.aba-faturamento-on {
    color: #000;
    font-weight: bold;
    border: 1px solid #000;
    padding: 0 5px;
    width: 100%;
    height: 40px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    transition: all 0.3s;
}

.aba-faturamento-on:hover {
    background-color: #EEEEEE;
    color: #000;
}

.aba-faturamento {
    width: 100%;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    height: 40px;
    border-radius: 10px 10px 0 0;
    border: 1px solid #CDCDCD;
    background-color: #fff;
    color: #CDCDCD;
    transition: all 0.3s;
}

.aba-faturamento:hover {
    background-color: #EEEEEE;
    color: #000;
}