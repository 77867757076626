.table-dom {
  width: 100%;
  border-collapse: collapse;
  border:1px rgba(224, 224, 224, 1) solid;
  border-radius: 4px;
}

.table-dom th {
  background-color:#FFF;
  border: #FFF;
  padding: 16px;
  border-bottom:1px rgba(224, 224, 224, 1) solid;
}

.table-dom tr:nth-child(even) {
  background-color:#FFF; 
} 

.table-dom tr:nth-child(odd) {
  background-color:#F0F0F5;
}

.table-dom td {
  padding: 16px;
  border-bottom:1px rgba(224, 224, 224, 1) solid;
}

#containerOpcoes{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.selectStatus {
  background-color: transparent;
  font-size: 14px;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 4px;
}

.btnOpcao {
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
  color: #003399;
  font-size: 16px;
  cursor: pointer;
}
.btnOpcao:hover {
  color: RoyalBlue;
}

#container-label-dialog-coleta {
  display: flex;
  flex-direction: column;
}

#container-status-table-coleta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#label-dialog-coleta {
  font-size: 16px;
  padding: 2px 0;
}

#label-dialog-tittle-coleta {
  font-size: 18px;
  font-weight: bold;
  padding: 4px 0 8px 0;
}

#container-table-dialog-coleta {
  display: grid;
  grid-template-columns: auto auto;
}

#label1-table-dialog-coleta {
    width: 150px;
    color: #000;
    font-weight: bold;
    padding: 10px 5px;
}

#label2-table-dialog-coleta {
    text-align: center;
    width: 150px;
    color: #000;
    padding: 10px 5px;
}

#container-button-accordeon-coleta {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.button-accordeon-coleta {
  display: flex;
  width: '90%';
  justify-content: center;
  padding: 5px;
}

#btn-coleta-check {
  background-color: #CEF6E3;
  transition: opacity 0.2s;
}

#btn-coleta-check:hover {
  opacity: 0.7;
}

#btn-coleta-uncheck {
  background-color: #F7F8E0;
  transition: opacity 0.2s;
}

#btn-coleta-uncheck:hover {
  opacity: 0.7;
}

.btn-coleta-check-default {
  width: 90%;
  height: 35px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #000;
}

#container-icons-table-coleta-options {
  display: flex;
  justify-content: space-evenly;
}

#qtdAgendamentosDisp {
  padding-bottom: 10px;
  color: black;
}

@media (max-width: 790px) {
  .table-dom {
      display: block;
      position: relative;
      width: 100%;
    }
    
  .table-dom thead,
  .table-dom tbody,
  .table-dom th,
  .table-dom td,
  .table-dom tr {
      display: block;
  }

  .table-dom td,
  .table-dom th {
      height: 40px;
  }

  .table-dom thead {
      float: left;
  }
    
  .table-dom tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
  }
    
  .table-dom tbody tr {
      display: inline-block;
  }

  .table-dom th,
  .table-dom td {
      padding-top: 8px;
  }

  #selectStatus {
      align-self: center;
      align-content: center;
  }
}

@media (max-width: 449px) {
  * {
      padding: 0px;
      margin: 0px;
  }
}
