div #container-title {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #003399;
    padding: 10px;
}
#button-dialog-back {
    justify-self: start;
    color: #FFF;
    background-color: transparent;
    border: transparent;
}
#icon-back {
    width: 60px;
    margin: 4px;
}
#content-label-title {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    align-self: center;
    text-align: center;
}