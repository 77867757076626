.conteudo-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;
}

.top-container-actions {
  margin-top: 1rem;
  display: flex;
  align-items: baseline;
}

@media screen and (min-width: 700px) {
  .top-container-actions {
    margin: 0;
  }
}
