.esteira {
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding-bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: baseline;

  overflow-x: auto;
}

.esteira::-webkit-scrollbar {
  height: 15px;
}

.esteira::-webkit-scrollbar-track {
  background: transparent;
}

.esteira::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
}

.esteira > .trilha {
  min-width: 240px;

  margin: 0 0.25rem;
}
