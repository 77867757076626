
.options-container{
    
    padding: 0;
    margin: 250px;
    
}

.options-paper{
    margin-left: 30px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-modal-options{
    margin-right: 0.4rem;
}