.cabecalho-caixa{
    width: 100%;
    max-width: 160px;
    border: 0.1rem solid #CDCDCD;
    border-radius: 0.2rem;
    padding: 0.5rem;
    margin-left: 2rem;
    height: 60px;
    margin-top:10px ;
}

.cabecalho-titulo{
    font-size: 12px;
}

.cabecalho-texto{
    margin-top: 0.5rem;
    font-weight: 500;
    text-align: center;    
    font-size: 16px;
}

