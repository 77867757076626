.table {
    width: 100%;
    border-collapse: collapse;
    border:1px rgba(224, 224, 224, 1) solid;
    border-radius: 4px;
}

.table th {
    background-color:#FFF;
    border: #FFF;
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}
 
.table tr:nth-child(even) {
    background-color:#FFF; 
} 

.table tr:nth-child(odd) {
    background-color:#F0F0F5;
}

.table td {
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}

#cliente {
    max-width: 270px;
}

#divPrioridade{
    display: flex;
    align-items: center;
}

.selectStatus {
    background-color: transparent;
    font-size: 14px;
    border-top: none;
    border-right: none;
    border-left: none;
    padding: 4px;
}

.btnOpcao {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: none;
    color: #003399;
    font-size: 16px;
    cursor: pointer;
  }
  .btnOpcao:hover {
    color: RoyalBlue;
  }

@media (max-width: 900px) {
    .table {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
    }

    .table thead,
    .table tbody {
        display: flex;
        flex-direction: column;
    }

    .table th {
        width: 100%;
        min-width: 95px;
    }

}

@media (max-width: 820px) {
    .table {
        display: block;
        position: relative;
        width: 100%;
      }
      
    .table thead,
    .table tbody,
    .table th,
    .table td,
    .table tr {
        display: block;
    }

    .table td,
    .table th {
        height: 40px;
    }

    .table thead {
        float: left;
    }
      
    .table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
      
    .table tbody tr {
        display: inline-block;
    }

    .table th,
    .table td {
        padding-top: 8px;
    }

    #selectStatus {
        align-self: center;
        align-content: center;
    }
}