.modal-wrapper.active{
    visibility: visible;
    opacity: 1;

}
.modal_adicionar{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    padding: 2rem;
}

@media screen and (max-width: 620px) {
    .modal_adicionar { 
        width: 95vw; 
        height:100vh;
    }
    .modal-title {
        font-size: 20px;
    }
}

.modal-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;

    background: rgba(4, 9, 17, .8);

    visibility: hidden;
    opacity: 0;
}

.modal-wrapper .modal{
    width: min(29rem, 60%);
    height: min(29rem, 60%);
    background: #E6E6FA;
    margin: auto;
    /*margin-top: 10rem;
    margin-left: 40rem;*/

    border-radius: .8rem;

    font-family: 'Poppins', sans-serif;
    text-align: center;

    padding: 4.4rem;
}

.modal-wrapper .modal h2{
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    color: black;
}
.grid_item_adicionar{
    display: flex;
    align-items: flex-end;
}

.modal-wrapper .modal{
    padding: 1rem 1.2rem;
    border: .1rem solid black;
    outline: none;
    width: min(70%, 30.2rem);
    font-size: medium;
}

.buttons{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}


#input {
    background: white;
}

#fecharSVG {
    cursor: pointer;
    float: right;
}

#fechar{
    cursor: pointer;
    float: right;
}