@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
.galeria-editor-post {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  margin-top: 1rem;
  margin-bottom: 1rem;

  cursor: pointer;
}

.modal-galeria {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-galeria > img {
  max-width: 95%;
  object-fit: cover;
}

.galeria-editor-post-imagem {
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  position: relative;

  height: 65px;

  animation: grow 0.3s;
}

.galeria-editor-post-imagem > img {
  width: 90px;
  height: 65px;
  object-fit: cover;

  border-radius: 4px;
}

.galeria-editor-post-imagem > .close-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 5px;
  right: 5px;

  background: white;

  width: 20px;
  height: 20px;

  border-radius: 2px;

  cursor: pointer;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.close-icon > svg {
  font-size: 20px;
}

@media screen and (min-width: 600px) {
  .galeria-editor-post-imagem > img {
    width: 120px;
    height: 80px;
    object-fit: cover;

    border-radius: 4px;
  }

  .close-icon > svg {
    font-size: 25px;
  }
  .galeria-editor-post-imagem > .close-icon {
    width: 25px;
    height: 25px;
  }
}
