.container-formulario-mapeamento-exames {
    display: flex;
    flex-wrap: wrap;
    background: white;
    margin-top: 1rem;
    padding: 1rem 2rem;
    justify-content: space-between;
}

.container-filtro-mapeamento-exames {
    display: flex; 
    flex-direction: row; 
}

.container-select-filtros {
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap;
}