.video-container {
  width: 100%;

  margin-top: 1rem;
  position: relative;
}

.video-container > video {
  display: block;
  margin: 1.5rem auto;
}

.video-close-icon {
  background: #003399;
  display: inline-flex;

  align-items: center;
  justify-content: center;

  border-radius: 4px;
  padding: 0.15rem;

  cursor: pointer;

  position: absolute;
  top: 0px;
  right: 8px;
}

.video-placeholder {
  background-color: #c4c4c4;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 300px;

  cursor: pointer;
}

.video-placeholder > p {
  font-size: 1.1rem;
  font-weight: 500;
}

.video-thumbnail {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  position: relative;

  margin-bottom: 0.5rem;
}

.video-thumbnail > img {
  max-width: 100%;
  object-fit: cover;
}
