#container-calendario-agenda {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 1rem;
  margin-top: 1.5rem;
}

.container-titulo-calendario-agenda {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.container-corpo-calendario-agenda {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.container-botoes-calendario-agenda {
  display: flex;
  flex-direction: row;
}

.nome-cliente-agendado {
  display: flex;
  width: 100%;

  color: green;
  font-size: 1rem;
  overflow: hidden;
  justify-content: center;
}

.nome-cliente-livre {
  display: flex;
  width: 100%;
  margin: 0 0.5rem;
  color: red;
  font-size: 1.2rem;
  justify-content: center;
}
