h1 {
  color: #003399;
}

.imgLogoLogin {
  width: 220px;
  height: 90px;
}

.labelAutenticacao {
  color: #000;
}
.version {
  text-align: center;
  position: fixed;
  bottom: 2em;
  left: 0;
  right: 0;
  color: #797979;
}
