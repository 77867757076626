.div-container-DOM {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  padding: 12px 30px;
  flex-grow: 1px;
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
  margin-top: 10px;
}
.grid-container-DOM {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 12px 30px;
  flex-grow: 1px;
  margin-bottom: 16px;
  margin-top: 10px;
}
.div-container-img {
  justify-content: center;
  margin-top: 10px;
}
img {
  width: 100%;
}

