.tittle-card-precos-exames {
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 0.4rem;
}

.value-card-precos-exames {
    display: flex;
    font-weight: bold;
    font-size: 1.5rem;
    justify-content: center;
}