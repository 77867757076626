.trilha {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 250px;
}

.trilha-titulo {
  font-size: 1.05rem;

  margin-bottom: 0.5rem;
}

.trilha-search-toggler {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.trilha-conteudo {
  padding: 0.5rem;
  width: 100%;

  position: relative;
}

.trilha-conteudo > .cards {
  margin-top: 0.25rem;
  max-height: 700px;
  overflow-y: auto;

  padding: 1rem 0;
}

.trilha-conteudo > .cards::-webkit-scrollbar {
  display: none;
}

.trilha-conteudo > .cards > .card-trilha {
  margin-bottom: 0.5rem;
}

.trilha-conteudo footer {
  display: flex;
  align-items: center;

  padding: 0.5rem;
  margin-top: 1rem;

  background: #f3f3f3;

  cursor: pointer;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.trilha-conteudo footer span {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
