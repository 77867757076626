.input-tool-control-samples {
    height: 35px;
    padding-left: 10px;
    margin: 10px 10px;
    border-radius: 20px;
}

#check-amostra {
    transform: scale(1.5);
}

.input-tool-control-samples:focus {
    background-color: #EFF5FB;
}

#container-add-samples {
    height: 100%;
    background-color: #F0F0F5;
}

.background-add-samples {
    height: 100%;
    background-color: #F0F0F5;
}

.container-form-dialog-add-samples {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border: 1px solid #000;
    padding: 8px 0;
    background-color: #FFF;
    border-radius: 4px;
    align-items: center;
    margin-top: 10px;
}

#container-edit-name-map {
    display: flex;
    align-items: center;
}

#input-edit-name-map {
    width: 320px;
    margin: 0 10px;
    padding: 3px 5px;
}

#button-edit-name-map {
    background-color: transparent;
    border: none;
    margin: 3px;
}

.content-form-dialog-add-samples {
    color: #000;
    font-size: 16px;
    margin: 0 10px;
}

#container-input-code-sample {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
}

#input-code-sample {
    margin: 16px 0 0 0;
    border-radius: 4px;
    max-width: 500px;
    width: 100%;
    height: 40px;
    padding-left: 10px;
}

#icon-urgency-add-samples {
    color: #F78181;
}

#icon-repetition-add-samples {
    color: #FACC2E;
}

.content-check-samples {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.input-check-samples {
    width: 20px;
    height: 20px;
}

.label-add-samples {
    margin-left: 10px;
    font-size: 20px;
}

#container-urgency-repetition {
    display: flex;
    flex-direction: row;
}

#label-urgencia {
    color: red;
    margin-right: 30px;
}

#label-repeticao {
    color: orange;
    margin-right: 30px;
}

#label-saliva {
    color: #003399;
}

#container-buttons-dialog-add-samples {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
}

.button-save-dialog-add-samples {
    min-width: 160px;
    height: 35px;
    border-radius: 4px;
    background-color: #01DFA5;
    color: #FFF;
    font-weight: bold;
    transition: opacity 0.2s;
}

.button-save-dialog-add-samples:hover {
    opacity: 0.7;
}

.button-delete-dialog-add-samples {
    width: 160px;
    height: 35px;
    border-radius: 4px;
    background-color: #F78181;
    color: #FFF;
    font-weight: bold;
    transition: opacity 0.2s;
}

.button-delete-dialog-add-samples:hover {
    opacity: 0.7;
}

.container-background-map-pcr {
    background: #F0F0F5;
    height: 100%;
}

#container-input-samples-map {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
}

#container-select-map {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 20px;
}

#inputlabel-select-placa {
    padding-left: 20px;
}

#select-map {
    width: 350px;
    min-width: 300px;
    margin-top: 10px;
    font-size: 18px;
    padding: 8px;
    border-radius: 4px;
}

#button-map-save-samples {
    background-color: #003399;
}


#button-map-add-samples {
    color: #FFF;
    background-color: #01DFA5;
    border-radius: 20px;
    align-self: center;
    justify-items: center;
    padding: 2px;
    margin-left: 16px;
    padding: 0 8px;
}

#view-map-back {
    background-color: #003399;
    margin-top: 0;
}

.container-dialog-sample-map {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.label-tittle-dialog-sample-map {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 10px 0;
}

#input-dialog-sample-map {
    max-width: 180px;
    align-self: center;
}

#input-dialog-add-name-map {
    max-width: 100%;
 }

.container-buttons-dialog-sample-map {
    display: flex;
    margin: 10px 0 20px 0;
    justify-content: space-around;
}

#container-inputlabel-add-samples {
    display: flex;
}

#label-number-add-samples {
    margin: 10px 30px 10px 0;
    padding: 10px 0;
    font-size: 20px;
    color: #000;
}

#button-remove-board {
    color: #F78181;
    font-size: 18px;
    background-color: transparent;
    border: 0px;
}

#button-remove-board:hover {
    cursor: pointer;
    color: red;
}

#button-delete-sample {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

#container-list-result-samples {
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    justify-content: space-between;
}

#device-name-process-samples {
    display: none;
}

.content-list-result-samples {
    color: #000;
}

#container-select-add-sample-map {
    display: flex;
    justify-content: center;
}

#select-add-sample-map {
    width: 200px;
}

#menuitem-sample-urgency {
    background-color: #FBEFEF;
    color: red;
    font-weight: bold;
}

.container-list-number-sample-divide {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
}

.list-number-sample-divide {
    font-size: 18px;
    margin: 4px 0;
}

@media print {
    
    #container-title-information-drive,
    #icon-back,
    #content-label-title,
    #button-map-save-samples,
    .button-map-style-samples,
    #inputlabel-select-placa,
    #container-select-map,
    #container-buttons-dialog-add-samples {
        display: none;
    }

    .container-background-map-pcr,
    .container-form-dialog-add-samples {
        border: none;
        padding: 0;
        margin: 0;
    }
    
    #container-list-result-samples {
        visibility: visible;
    }

    #device-name-process-samples {
        display: inline;
    }

    .container-button-cell-map,
    .container-button-cell-map-off {
        background-color: #928c99 !important;
        -webkit-print-color-adjust: exact;
    }

    @page{
        size: landscape;
    }

}