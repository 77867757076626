.grid-container-Dom-vis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  padding: 12px 30px;
  flex-grow: 1px;
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
}
