.anexos-dialog {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.anexos-dialog > .anexo {
  padding: 0.5rem;
  margin-right: 15px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  background: #003399;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.anexo .filename {
  cursor: pointer;
}
.dialog-add-button {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

.anexo .time {
  font-size: 0.7rem;
  font-style: italic;

  margin-left: 8px;

  align-self: flex-end;
}

.anexo span:hover {
  text-decoration: underline;
}

.dialog-add-button:hover {
  background: rgba(195, 191, 191, 0.3);
}

.dialog-add-button > span {
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}
