.card {
  margin-top: 1.4rem;
  width: 14rem;
}
.grafico {
  height: 20rem;
  margin-top: 1.5rem;
}
.grid-item {
  margin-top: 10px;
  grid-column: 1 / 3;
}

.data {
  margin-left: 1rem;
}
@media (max-width: 650px) {
  .card {
    margin-top: 1.4rem;
    width: 10rem;
  }
}
