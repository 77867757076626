.card-trilha {
  background: rgba(243, 243, 243, 0.7) !important;

  font-size: 0.95rem;
  color: black;

  padding: 0.75rem;
  padding-bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  border: 1px solid rgba(196, 196, 196, 0.6);
  border-bottom: 4px solid #003399;
  transition: all 0.3s;
}

.card-trilha:hover {
  transition: all 0.3s;

  transform: translateY(-10px);
  border-bottom: 4px solid #ed3237;
  box-shadow: 0 7px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0px 4px 5px 0 rgba(0, 0, 0, 0.14);
}

.card-trilha-texto > p {
  color: black;
  margin-bottom: 7px;
}

.card-trilha-texto > .identifier {
  color: #003399;
  font-weight: 500;
}

.card-trilha-texto > svg {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.card-trilha-texto > svg:hover {
  color: rgba(0, 0, 0, 1);
  transition: color 0.3s;
}

.popover-more {
  list-style: none;
  padding: 1rem;
}

.popover-more li {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;

  cursor: pointer;
}

.popover-more li:hover {
  color: #003399;
}

.card-trilha-botoes {
  display: flex;
  flex-direction: column;
}

.card-trilha-botoes > * {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;

  font-size: 22px !important;
}

.card-trilha-botoes > :not(.card-trilha-botoes:last-child) {
  margin-bottom: 8px;
}

.card-trilha-botoes:last-child {
  margin-bottom: 4px;
}

.card-trilha-botoes > *:hover {
  color: rgba(0, 0, 0, 1);
  transition: color 0.3s;
}
