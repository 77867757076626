.topic-pill {
  background-color: #003399;
  padding: 0.5rem;

  display: inline-flex;
  align-items: center;

  border-radius: 4px;

  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.topic-pill > p {
  color: white;
  font-weight: 500;
  font-size: 1rem;

  margin-right: 1rem;
}
