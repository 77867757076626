.article-section {
  padding: 2.5rem;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;

  padding: 1.5rem;
}

.article-section-title {
  width: 100%;
}

.article-section-body {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
