#container-filter-check-drive {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

#container-check-drive {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.content-check-drive {
    display: flex;
    align-items: center;
}

.content-label-drive {
    display: flex; 
    flex-direction: row; 
    align-items: center;
}

.myCheck-drive-table {
    width: 16px;
    height: 16px;
    margin: 10px 10px;
}

h1 {
    margin-top: 250px;
    text-align: center;
    color: #000;
}

@media (max-width: 449px) {
    * {
        padding: 0px;
        margin: 0px;
    }
}