
.paper-categoria{
    padding: 13px;
}

@media only screen and (min-width: 900px){
    #header-categoria{
        display: flex;
        justify-content: space-between;
    }
}

