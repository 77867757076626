.controle-acesso-wrapper {
  max-width: 1400px;
}

.controle-acesso-wrapper {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.controle-acesso-topo {
  padding: 2rem 2.5rem;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.controle-acesso-baixo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}

.controle-acesso-baixo-conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  margin-top: 2rem;
  margin-bottom: 1.5rem;

  position: relative;
}

.painel-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  margin-bottom: 3rem;
}

.painel-buttons-wrapper > button:first-of-type {
  margin-bottom: 1rem;
}

.painel-buttons-wrapper > button,
.painel-buttons-wrapper > button:hover,
.MuiDialogActions-root > button:hover,
.MuiDialogActions-root > button {
  background-color: #003399;
  color: white;

  max-width: 500px;
  font-size: 1rem;
}

.painel-buttons-wrapper > button.secondary {
  background-color: #ed3237;
}

.painel-buttons-wrapper > button {
  width: 100%;
}

.painel-secao {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 500px;
}

.painel-secao h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.painel-secao-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  justify-content: space-between;

  min-height: 300px;
}

.card-conteudo {
  width: 92%;
  height: auto;

  overflow-y: auto;

  margin: 1.5rem 0;
}

.card-footer {
  cursor: pointer;

  display: flex;
  align-items: center;

  border-top: 2px solid rgba(0, 0, 0, 0.2);

  padding: 0.8rem 0.25rem;

  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.card-footer > span {
  font-size: 1rem;
  font-weight: bold;

  margin-left: 10px;

  color: rgba(0, 0, 0, 0.6);
}

.painel-botoes {
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 90%;
  max-width: 415px;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.painel-botao {
  cursor: pointer;

  width: 55px;

  padding: 0.5rem 1rem;

  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;

  background-color: #003399;

  border-radius: 5px;
}

.modulo-select,
.usuario-selecionado {
  min-width: 250px !important;
}

.usuario-selecionado {
  margin-right: 30px !important;
}

.painel-buttons-wrapper > button:disabled {
  background-color: #c4c4c4;
  color: rgba(0, 0, 0, 0.4);

  cursor: not-allowed;
}

.back-icon{
  cursor: pointer;
}

@media (min-width: 600px) {
  .controle-acesso-wrapper {
    margin-top: 4rem;
  }
}

@media (min-width: 900px) {
  .painel-buttons-wrapper {
    flex-direction: row;
    justify-content: flex-end;
  }

  .painel-buttons-wrapper > button:first-of-type {
    margin: 0;
    margin-right: 2rem;
  }

  .painel-buttons-wrapper > button {
    align-self: flex-end;
    max-width: auto;
    width: auto;
  }

  .controle-acesso-baixo-conteudo {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .painel-secao {
    max-width: 400px;
  }

  .painel-secao-card {
    height: 500px;
  }

  .painel-botoes {
    flex-direction: column;

    width: 55px;
    height: 300px;
    margin: 0 1.5rem;
  }
}

@media (min-width: 1280px) {
  .controle-acesso-baixo {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}
