/* .imagem-usuario{

    margin-left: 25px;
  padding: 0.5rem;

  font-size: 1.4rem;
  color: white;
  font-weight: bold;

  background-color: #ed3237;

  border-radius: 100%;

  height: 110px;
  width: 110px;

  display: flex;
  justify-content: center;
  align-items: center;
} */

#preview-image{
  
  
  
  border-radius: 100%;

  height: 150px;
  width: 150px;
  
  
  

  object-fit: cover;
}

.input-image{
    display: none;
}

/* #image-upload{

} */
.MuiGrid-root .MuiGrid-grid-md-6{
  margin: 15px;
}

.button-upload{
  margin-top: 10px ;
}

.imagem-do-perfil{
  margin-bottom: 10px;
  
}
