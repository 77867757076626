main.espermograma {
  width: 95%;
  max-width: 1000px;
  margin: 4rem auto;
}

.espermograma-top-paper {
  padding: 2rem;
  margin-bottom: 3rem;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.espermograma-btns {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1rem;
}

.espermograma-btns > button {
  font-size: 1.1rem;
  text-transform: uppercase;
  padding: 0.5rem;
}

/*
.espermograma-btns button:first-child {
  margin-bottom: 1rem;
  background-color: #fa0d13 !important;
}
*/

.espermograma-help {
  background-color: #003399;
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border-radius: 4px;

  margin-bottom: 1rem;
  margin-left: auto;
}

@media screen and (min-width: 450px) {
  .espermograma-top-paper {
    flex-direction: row;
  }

  .espermograma-btns {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@media screen and (min-width: 700px) {
  .espermograma-btns {
    flex-direction: row;
  }
  .espermograma-btns button:first-child {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
