.conteiner_horarios {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coleta_concluido {
  padding: 8px;
  background-color: #58c85c;
  border: none;
  border-radius: 3px;
  transition: 0.2s;
}

.coleta_concluido:hover {
  background-color: #41a146;
}

.coleta_pendente {
  padding: 8px;
  border-radius: 3px;
  background-color: #e4e4e4;
  border: none;
  transition: 0.2s;
}

.coleta_pendente:hover {
  background-color: #a0a0a0;
}
