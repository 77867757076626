@keyframes fadeFromTop {
  from {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 10px));
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.my-modal-body {
  animation: fadeFromTop 0.5s;
}

.modal-title {
  margin: 0;
  margin-bottom: 1.25rem;

  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.modal-text {
  color: black;
  font-size: 1.1rem;

  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 1.5rem;
}

.modal-buttons > button {
  font-size: 1.1rem;
}
