.add-section-button {
  background-color: #003399;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6), 0px 4px 6px rgba(0, 0, 0, 0.1);

  width: 40px;
  height: 40px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  position: absolute;
  left: calc(50% - 15px);
  transform: translateY(-50%);

  cursor: pointer;
}

.add-section-button:hover {
  width: 55px;
  height: 55px;

  transition: 0.3s;
}
