@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
    --primary-color-300: "#598FE0";
    --primary-color-400: "#3065C1";
    --primary-color-500: "#003399";
    --primary-color-600: "#002783";
    --primary-color-700: "#001D6E";

    --secondary-color-300: "#64A4E2";
    --secondary-color-400: "#3C7CC5";
    --secondary-color-500: "#0C4AA0";
    --secondary-color-600: "#083989";
    --secondary-color-700: "#062A73";

    --success-color-300: "#8BDA72";
    --success-color-400: "#5BB549";
    --success-color-500: "#24841B";
    --success-color-600: "#147113";
    --success-color-700: "#0D5F13";

    --warning-color-300: "#FBD964";
    --warning-color-400: "#F7C73D";
    --warning-color-500: "#F2AD00";
    --warning-color-600: "#D08E00";
    --warning-color-700: "#AE7200";

    --danger-color-300: "#DF8969";
    --danger-color-400: "#C05941";
    --danger-color-500: "#962112";
    --danger-color-600: "#81110D";
    --danger-color-700: "#6C090D";
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #F0F0F5;
    -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
    font: 400 18px Roboto, sans-serif;
}

button {
    cursor: pointer;
}