#icon-menu {
  color: #fff;
}

.cabecalho {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #003399;
  height: 70px;
}

.cabecalho-icones {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 15px;
}

.icone-usuario {
  margin-right: 5px;
  padding: 0.5rem;

  font-size: 1.4rem;
  color: white;
  font-weight: bold;

  background-color: #ed3237;

  border-radius: 100%;

  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  object-fit: cover;
}

.imagem-usuario {
  margin-right: 5px;
  padding: 0.5rem;

  font-size: 1.4rem;
  color: white;
  font-weight: bold;

  border-radius: 100%;

  height: 60px;
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  object-fit: cover;
}

.imgLogo {
  height: 45px;
  width: 80px;
}

#logo {
  float: left;
  cursor: pointer;
}

#titulo {
  /* margin-left: 10px; */
  color: #fff;
}

#titulo span {
  font-size: 24px;
  font-weight: bold;
}

#usuario {
  margin-left: auto;
  margin-right: 20px;
}

.linkSair {
  color: #ffffff;
  text-decoration: none;
}

.linkSair:hover {
  color: yellow;
}

#iconSair {
  margin-right: 8px;
  font-size: 18px;
}

.apps-popover {
  padding: 0.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;

  width: 270px;
}

.acessos-header {
  text-align: flex-start;
  margin-bottom: 5px;
  padding: 8px 0 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.acessos-header:hover {
  background-color: rgba(195, 191, 191, 0.3);
}

.user-icon-row {
  border-top: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 200px;

  padding: 0.75rem;

  cursor: pointer;
}

.user-icon-row:hover {
  background-color: rgba(195, 191, 191, 0.3);
}

.user-icon-row > p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

@media (max-width: 449px) {
  * {
    padding: 0px;
    margin: 0px;
  }

  #usuario {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 4px;
  }

  #usuario p {
    font-size: 12px;
  }

  #titulo span {
    font-size: 18px;
    font-weight: bold;
  }

  #iconSair {
    margin-right: 8px;
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  .apps-popover {
    width: 340px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
