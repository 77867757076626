#container-formulario-lotes-faturamento {
    width: 100%;
    padding: 0.8rem 1.5rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    
}

.formulario-lotes-filtro-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border: 0.1rem solid #CDCDCD;
    border-radius: 0.2rem;
    padding: 1rem;
}

.container-botao-sincronizacao {
    display: flex;
    align-self: center;
    height: 3rem;
}