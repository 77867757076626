.card {
  min-height: 200px;
  width: 275px;
  margin: 5px;
}

.conteiner {
  display: flex;
  justify-content: center;
  align-items: top;
}

.adicionar_coleta {
  min-height: 200px;
  width: 275px;
  margin: 10px 10px;
  cursor: pointer;
  transition: 0.2s;
  background-color: #b5ffb5;
}
.botao_adicionar {
  width: 2rem;
  height: 2rem;
}
.horarios {
  margin-top: 4px;
}

.adicionar_coleta:hover {
  background-color: #bebebe;
}

.nome_paciente {
  display: flex;
  justify-content: center;
  color: black;
  background-color: #eeeeee;
  border-radius: 2px;
}
.nome_exame_card {
  display: flex;
  justify-content: center;
  color: black;
  background-color: #eeeeee;
  border-radius: 2px;
}
.poltrona_card {
  display: flex;
  justify-content: center;
  color: black;
  border-radius: 20px;
  background-color: #eeeeee;
  padding: 5px;
}
.card_adicionar {
  display: flex;
  justify-content: center;
  color: black;
}

.conteudo {
  text-align: center;
  align-content: center;
}
