#Container {
    padding-top: 0.5rem;
}

#textfield{
    background-color: #FFFFFF;
}

#gridContainer{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

form{
    margin-top: 0.5rem;
}

@media (min-width:320px) {
    #Container {
        padding-top: 0.2rem
    }
}