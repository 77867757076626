#content-form-ctrl-samples {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 0 0 10px 10px;
    padding: 20px 30px;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #CDCDCD;
    align-items: center;
}

.button-form-samples {
    width: 160px;
    min-width: 120px;
    height: 36px;
    margin: 10px 10px 0 10px;
    background-color: #003399;
    color: #FFF;
    border-radius: 4px;
    transition: opacity 0.2s;
}

.button-form-samples:hover {
    opacity: 0.7;
}

@media (max-width: 650px) {
    .button-form-samples {
        margin: 10px 20px 0 0;
        justify-self: center;
    }

}

@media (max-width: 499px) {
    #content-form-ctrl-samples {
        justify-content: center;
    }

    .button-form-samples {
        margin: 10px 0 0 0;
    }

}