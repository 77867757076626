.my-button {
  font-size: 1rem;
}

.my-button:disabled {
  color: rgba(255, 255, 255, 0.6);
  background-color: #a0a3a9;
}

.my-button:hover {
  background-color: rgba(0, 51, 153, 0.8);
}

.my-button.primary {
  background-color: #003399;
  color: white;
}

.my-button.primary:hover {
  background-color: #003399;
  color: white;
}

.my-button.secondary {
  background-color: #ffffff !important;
  color: #003399 !important;
}

.my-button.secondary:hover {
  background-color: #f3f3f3 !important;
}
