#container-map {
    margin: 15px 0;
}

#grid-letter {
    text-align: center;
    width: 100%;
    margin-left: 50px;
}

.cell-label-letters-map {
    height: 65px;
}

.label-letters-map {
    font-size: 16px;
}

#inputlabel-numbers-map {
    margin: 15px 0;
    text-align: center;
}

.container-cell-map {
    background-color: #FFF;
    padding: 5px 4px;
    text-align: 'center';
    height: 65px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
}

.container-label-map-cell {
    display: flex;
    flex-direction: column;
}

.container-icon-urgency-map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

#icon-urgency-map {
    padding: 0;
    margin: 0;
    font-size: 17px;
    color: #FA5858;
}

#icon-repetition-map {
    padding: 0;
    margin: 0;
    font-size: 17px;
    color: #FACC2E;
}

#icon-spittle-map {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #003399;
}

.inplabel-initials-map {
    text-align: end;
    font-size: 10px;
}

.inplabel-number-map {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    margin: 0;
}

.inplabel-code-map {
    text-align: center;
    font-size: 10px;
}

.inplabel-result-map {
    text-align: center;
    font-size: 12px;
    margin: 3.5px 0;
    padding: 1px 6px;
    border-radius: 8px;
}

#inputlabel-letters-map {
    display: flex;
    justify-content: space-around;
    margin: 40px 5px;
    
} 

.container-results-code {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.content-results {
    display: flex;
    flex-direction: column;
}

.label-code-cell-map {
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
}

.container-button-cell-map-dark {
    background-color: #CDCDCD;
    padding: 5px 4px;
    text-align: 'center';
    height: 65px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
}

.container-button-cell-map-dark:hover {
    background-color: #eef1c1;
    border: 3px solid blue;
    cursor: pointer;
}

.container-button-cell-map-light {
    background-color: #FFF;
    padding: 5px 4px;
    text-align: 'center';
    height: 65px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
}

.container-button-cell-map-light:hover {
    background-color: #eef1c1;
    border: 3px solid blue;
    cursor: pointer;
}

.container-button-cell-map-off-dark {
    background-color: #CDCDCD;
    padding: 5px 4px;
    text-align: 'center';
    height: 65px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: default;
}

.container-button-cell-map-off-light {
    background-color: #FFF;
    padding: 5px 4px;
    text-align: 'center';
    height: 65px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: default;
}

@media print {

    .container-button-cell-map-dark,
    .container-button-cell-map-off-dark {
        background-color: #ECE9E9 !important;
        -webkit-print-color-adjust: exact;
    }

    @page{
        size: landscape;
    }

}
