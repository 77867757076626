.container-buttons-menu-samples {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.button-menu-samples-on {
    width: 178px;
    min-width: 120px;
    height: 40px;
    background-color: #003399;
    color: #FFF;
    font-weight: bold;
    border-radius: 4px;
    margin: 12px 2px;
    transition: opacity 0.2s;
}

.button-menu-samples-on:hover {
    opacity: 0.7;
}

.button-menu-samples {
    width: 178px;
    min-width: 120px;
    height: 40px;
    background-color: #FFF;
    color: #003399;
    font-weight: bold;
    border-radius: 4px;
    margin: 12px 2px;
    transition: background-color 0.2s
}

.button-menu-samples:hover {
    background-color: #F2F2F2;
}

.button-menu-divide-samples {
    float: right;
    width: 178px;
    min-width: 120px;
    height: 40px;
    background-color: #01DFA5;
    color: #FFF;
    font-weight: bold;
    border-radius: 4px;
    margin: 12px 2px;
    transition: opacity 0.2s
}

.button-menu-divide-samples:hover {
    opacity: 0.7;
}

@media (max-width: 449px) {
    .container-buttons-menu-samples {
        margin-bottom: 20px;
        justify-content: space-around;
    }

    .button-menu-samples-on {
        width: 49%;
        margin: 3px 0;
    }
    
    .button-menu-samples {
        width: 49%;
        margin: 3px 0;
    }

}