#divData {
    display: flex;
    flex-direction: column;
    color: #000;
}

#labelData {
    font-size: 12px;
    color: #ACACAC;
    padding-bottom: 5px;
}

#inputData {
    color: #000;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    text-align: center;
    width: 170px;
}