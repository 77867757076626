#table-drive {
    width: 100%;
    border-collapse: collapse;
    border:1px rgba(224, 224, 224, 1) solid;
    border-radius: 4px;
}

#table-drive th {
    background-color:#FFF;
    border: #FFF;
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}
 
#table-drive tr:nth-child(even) {
    background-color:#FFF; 
} 

#table-drive tr:nth-child(odd) {
    background-color:#F0F0F5;
}

#table-drive td {
    padding: 16px;
    border-bottom:1px rgba(224, 224, 224, 1) solid;
}

#button-option {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: none;
    color: #003399;
    font-size: 20px;
    cursor: pointer;
}

#button-option:hover {
    color: RoyalBlue;
}

#container-label-dialog-drive {
    display: flex;
    flex-direction: column;
}

#container-status-atendimento-table-drive {
    display: flex;
    align-items: center;
}

#icon-status-atendimento-table-drive-blue {
    padding: 10px;
    margin-right: 12px;
    font-size: 20px;
    color: #5882FA;
}

#icon-status-atendimento-table-drive-orange {
    padding: 10px;
    font-size: 20px;
    color: #DBA901;
}

#icon-status-atendimento-table-drive-red {
    padding: 10px;
    margin-right: 12px;
    font-size: 20px;
    color: #FE2E2E;
}

#icon-status-atendimento-table-drive-green {
    padding: 10px;
    font-size: 20px;
    color: #01DFA5;
}

#container-status-table-drive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#icon-priority {
    color: #FE2E2E;
    margin-right: 4px;
}

#icon-money {
    margin-right: 4px;
    color: #088A29;
}

#icon-status-atendimento-table-drive-gray {
    padding: 10px;
    margin-right: 12px;
    font-size: 20px;
    color: #A4A4A4
}

#label-dialog-drive {
    font-size: 16px;
    font-weight: bold;
    padding: 4px 0;
    color: #FFF;
}

#container-table-dialog {
    display: grid;
    grid-template-columns: auto auto;
}

#label1-table-dialog-drive {
    width: 150px;
    color: #000;
    font-weight: bold;
    padding: 10px 5px;
}

#label2-table-dialog-drive {
    width: 150px;
    color: #000;
    padding: 10px 5px;
}

#button-option-small {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: none;
    color: #003399;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 5px;
    margin-left: 30px;
}

#button-option-small:hover {
    color: RoyalBlue;
}

#table-row-mobile-drive-green {
    background-color: #01DFA5;
}

#table-row-mobile-drive-orange {
    background-color: #DBA901;
}

#table-row-mobile-drive-red {
    background-color: #FE2E2E;
}

#table-row-mobile-drive-blue {
    background-color: #5882FA;
}

#table-row-mobile-drive-gray {
    background-color: #A4A4A4;
}

@media (max-width: 650px) {

    #table-drive {
        display: block;
        position: relative;
        width: 100%;
      }
      
    #table-drive thead,
    #table-drive tbody,
    #table-drive th,
    #table-drive td,
    #table-drive tr {
        display: block;
    }

    #table-drive td,
    #table-drive th {
        height: 40px;
    }

    #table-drive thead {
        float: left;
    }
      
    #table-drive tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
      
    #table-drive tbody tr {
        display: inline-block;
    }

    #table-drive th,
    #table-drive td {
        padding-top: 8px;
    }

    #container-status-table-drive {
        justify-content: start;
    }

    #icon-priority {
        color: #FFF;
    }

}