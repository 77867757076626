.search-modal-title {
  margin-bottom: 1.5rem;
}

.data-instance {
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;
}

.data-container > hr {
  border-top: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 0.5rem;
}

.data-instance:hover {
  background-color: #f1f1f1;
}

.data-instance > p {
  color: black;
  font-size: 1.1rem;
}
