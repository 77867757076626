button{
    
    border: 0;
    background: transparent;
}

.input-text-modal{
    padding: 0.8rem;
}



#botao-cadastrar{
    display: flex;
    justify-content: flex-end;

    
}

#botao-fechar{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

@media screen and (max-width: 600px) {
    #botao-cadastrar{
        display: flex;
        justify-content: space-evenly;

        padding: 0.8rem;
        }
    
        
    
}