.user-search-wrapper {
  max-width: 1000px;

  display: flex;
  align-items: flex-end;
}

.controle-acesso-wrapper {
  max-width: 1400px;
}

.user-search-wrapper,
.controle-acesso-wrapper {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.controle-acesso-topo {
  padding: 2rem 2.5rem;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.modulo-select,
.usuario-selecionado {
  min-width: 250px !important;
}

.usuario-selecionado {
  margin-right: 30px !important;
}

@media (min-width: 600px) {
  .user-search-wrapper,
  .controle-acesso-wrapper {
    margin-top: 4rem;
  }
}
