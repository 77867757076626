.respostas-wrapper {
  width: 95%;
  max-width: 1000px;

  margin-left: auto;
  margin-right: auto;
}

.sintomas-paciente *,
.condicoes-paciente * {
  color: black;
}

.sintomas-paciente p,
.condicoes-paciente p {
  font-size: 16px;
  margin-left: 8px;
  margin-bottom: 10px;
}
