#container-form-ctrl-drive {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    border-radius: 4px;
    padding: 20px 30px;
    margin-top: 20px;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #CDCDCD;
    align-items: center;
}

#button-add-drive {
    background-color: #003399;
    color: #FFF;
    padding: 4px 4px;
    border-radius: 4px;
    width: 100px;
}

#button-add-drive:hover {
    background-color: navy;
}

#button-update-drive {
    background-color: #01DFA5;
    margin-left: 10px;
    color: #FFF;
    padding: 4px 4px;
    border-radius: 4px;
    width: 100px;
}

#button-update-drive:hover {
    background-color: #04B486;
}

#container-search-cpf {
    display: grid;
    grid-template-columns: auto;
    background-color: #F0F0F5;
    padding: 1rem;
    margin: 1rem 1rem;
    justify-content: center;
}

#input-search-cpf {
    height: 3.5rem; 
    width: 25rem;
    border-radius: 0.3rem;
    padding-left: 1rem;
    font-size: 2.5rem;
}

#button-search-cpf {
    margin-top: 0.6rem;
    height: 3.5rem;
    background-color: #003399;
    color: #FFF;
    border-radius: 0.3rem;
    margin-bottom: 10px;
    font-size: 2rem;
}

#button-possui-pedido {
    margin-top: 0.6rem;
    height: 3.5rem;
    background-color: #01DFA5;
    color: #000;
    border-radius: 0.3rem;
    margin-bottom: 10px;
    font-size: 2rem;
}

#button-nao-possui-pedido {
    margin-top: 50px;
    height: 3.5rem;
    background-color: #DBA901;
    color: #000;
    border-radius: 0.3rem;
    font-size: 2rem;
}

#button-search-cpf:hover {
    background-color: navy;
}

.container-gallery-fotos-client-drive {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 1px;
}

.content-gallery-fotos-client-drive {
    margin: 15px 10px;
}

#h2-drive {
    color: black;
}

#container-data-client-drive {
    display: grid;
    grid-template-columns: 6rem 5rem 5rem 5rem 5rem 5rem 5rem 6rem;
    justify-content: center;
    padding-left: 6rem;
    padding-right: 6rem;
}

#container-send-client-drive {
    display: grid;
    justify-content: center;
    padding-left: 1px;
}

.container-select-client-drive {
    height: 2.6rem;
    font-size: 2rem;
    border-radius: 0.3rem;
    min-width: 5.7rem;
}

#container-select-covid-client-drive {
    height: 30px;
    width: 60px;
    margin-bottom: 200px;
    align-self: center;
}

#container-select-novo-pedido-client-drive {
    height: 30px;
    width: 60px;
    margin-bottom: 50px;
    align-self: center;
}

#oficioIcon {
    align-self: center;
}

#img-logo-foto {
    display: flex;
    width: 25rem;
    height: 30rem;
}

#img-logo-foto2 {
    display: flex;
    width: 12rem;
    height: 16rem;
}

#button-atualiza-pedido {
    display: flex;
    width: 160px;
    height: 40px;
    font-size: 35px;
    justify-content: center;
}

#container-input-client-drive-new-pedido {
    justify-items: center;
}

.input-new-code {
    width: 160px;
}

#foto {
    display: none;
}

.container-button-client-drive-foto {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
    grid-column: 1 / 9;
    margin-bottom: 5rem;
}

#content-button-client-drive-foto-salva {
    margin: 10px 0 20px 0;
    width: 12rem;
    height: 3rem;
    background-color: #01DFA5;
    font-weight: bold;
    font-size: 2rem;
    color: #fff;
    border-radius: 0.3rem;
}

#content-button-client-drive-foto-salva2 {
    margin: 10px 0 20px 0;
    width: 150px;
    height: 30px;
    background-color: #01DFA5;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    border-radius: 0.3rem;
}

#content-button-client-drive-foto-descarta {
    margin: 10px 0 20px 0;
    width: 12rem;
    height: 3rem;
    background-color: #FE2E2E;
    opacity: 0.8;
    font-weight: bold;
    font-size: 2rem;
    color: #fff;
    border-radius: 0.3rem;
}

#button-search-adress-drive {
    margin-top: 0.8rem;
    font-size: 3.5rem;
}

.container-input-client-drive-send {
    display: flex;
    margin-top: 10rem;
    flex-direction: column;
    grid-column: 1 / 9;
}

.container-input-client-drive-unico {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    grid-column: 1 / 9;
}

.container-input-client-drive-unico2 {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    grid-column: 1 / 9;
    margin: 40px 0;
}

.container-input-client-drive-unico3 {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    grid-column: 1 / 9;
}

.container-input-client-drive-tiple1 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 1 / 3;
}

.container-input-client-drive-tiple2 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 3 / 5;
}

.container-input-client-drive-small1 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 5 / 8;
}

.container-input-client-drive-tiple3 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    justify-self: end;
    flex-direction: column;
    grid-column: 8 / 9;
}

.container-input-client-drive-data {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    justify-self: center;
    flex-direction: column;
    grid-column: 5 / 9;
}

.container-input-client-drive-duplo1 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 1 / 5;
}

.container-input-client-drive-duplo2 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    justify-self: end;
    flex-direction: column;
    grid-column: 5 / 9;
}

.container-input-client-drive-middle {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 1 / 5;
}

.container-input-client-drive-big-cep {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 1 / 5;
}

.container-input-client-drive-big {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    flex-direction: column;
    grid-column: 1 / 8;
}

.container-input-client-drive-small2 {
    display: flex;
    margin-top: 8px;
    max-width: 95%;
    justify-self: end;
    flex-direction: column;
    grid-column: 8 / 9;
}

#label-data-drive {
    font-size: 1rem;
}

#input-data-drive {
    width: 104%;
    font-size: 2rem;
    border-radius: 0.3rem;
    height: 2.7rem;
    padding-left: 0.5rem;
}

#container-foto-drive-img {
    align-self: center;
}

#img-logo-foto-teste {
    display: flex;
    width: 500px;
    height: 700px;
}

#button-add-more-photos {
    display: flex;
    width: 200px;
    height: 40px;
    background-color: #F4FA58;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}

#icon-add-photo {
    margin-right: 4px;
}

#container-note-drive {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
}

#content-note-drive {
    width: 300px;
}

.h3-drive {
    text-align: center;
    color: black;
}

.h4-drive {
    text-align: center;
    color: black;
    border: 1px solid red;
    margin-top: 10px;
}

.container-input-client-drive-unico-final {
    display: flex;
    margin-top: 12rem;
    flex-direction: column;
    grid-column: 1 / 9;
}

#label-info-pedido-drive {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
}

#label-info-pedido-drive-final {
    margin-top: 15rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
}

#label-name-tittle-info-pedido-drive {
    margin-top: 2rem;
    font-size: 1rem;
}

#label-name-info-pedido-drive {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
}

#label-dados-info-pedido-drive {
    margin-top: 1.5rem;
    font-size: 2rem;
}

.myCheck-drive-table {
    font-size: 2.3rem;
    height: 3rem;
    display: flex;
}

div #container-title-information-drive {
    display: flex;
    background-color: #003399;
    padding: 10px;
    justify-content: space-between;
}

.label-default-tittle-drive {
    font-size: 1rem;
}

#container-form-information-drive {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 20px 0;
    padding: 20px;
    background-color: #F0F0F5;
    border-radius: 4px;
    align-items: center;
}

#content-form-information-drive-label {
    font-size: 16px;
    padding: 6px 0;
}

#container-select-information-drive {
    display: flex;
    flex-direction: column;
}

#content-form-information-drive-tittle-select {
    font-size: 12px;
}

#content-form-information-drive-select {
    max-width: 180px;
    height: 30px;
    margin: 5px 0;
}

#appbar-information-drive {
    background-color: #003399;
}

#container-labels-aba-informacoes-drive {
    display: grid;
    grid-template-columns: auto auto auto;
}

.container-label-aba-informacoes-drive {
    display: flex;
    flex-direction: column;
}

.container-label-aba-informacoes-drive2 {
    display: flex;
    grid-column: 1 / 4;
    justify-self: center;
    margin-top: 80px;
}

#label-aba-remove-inforamacoes-drive {
    color: #F78181;
}

#label-aba-remove-inforamacoes-drive:hover {
    cursor: pointer;
    color: red;
}

.label-aba-inforamacoes-drive {
    color: #000;
    font-size: 12px;
    margin: 0 10px;
}

.content-label-aba-informacoes-drive {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin: 6px 10px;
}

.container-button-copy-drive {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-copy-drive {
    background-color: transparent;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    border: none;
    color: #003399;
    font-size: 16px;
    cursor: pointer;
  }
  .button-copy-drive:hover {
    color: RoyalBlue;
  }

@media (max-width: 800px) {
    #container-form-information-drive {
        grid-template-columns: auto auto;
    }

}

@media (max-width: 700px) {

    #container-form-information-drive {
        justify-content: center;
        grid-template-columns: auto;
    }

}

@media (max-width: 600px) {
    #container-form-ctrl-drive {
        grid-template-columns: auto;
        justify-content: center;
    }

    #button-add-drive {
        margin-top: 10px;
        justify-self: center;
    }

    #container-labels-aba-informacoes-drive {
        grid-template-columns: auto auto;
    }

}

@media (max-width: 499px) {
    #container-labels-aba-informacoes-drive {
        grid-template-columns: auto;
    }

}