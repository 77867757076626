main.agenda-paternidade {
  width: 95%;
  max-width: 1000px;
  margin: 3rem auto;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.agenda-paternidade-topo {
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .agenda-paternidade-topo > button {
    margin-top: 1rem;
    margin-left: auto;
  }
}

.agenda-paternidade-datas {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 80%;
}

.dialog-textarea {
  resize: none;
  width: 100%;

  padding: 0.5rem;
  margin-top: 0.8rem;

  border: 2px solid #979797;
}

.dialog-textarea:focus {
  border: 2px solid #003399;
}

.dialog-add-button {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

.dialog-add-button:hover {
  background: rgba(195, 191, 191, 0.3);
}

.dialog-add-button > span {
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}

.anexos-dialog {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.anexos-dialog > .anexo {
  padding: 0.5rem;
  margin-right: 15px;
  margin-bottom: 0.5rem;

  background: #003399;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.anexo .filename {
  cursor: pointer;
}

.anexo .time {
  font-size: 0.7rem;
  font-style: italic;

  margin-left: 8px;

  align-self: flex-end;
}

.anexo span:hover {
  text-decoration: underline;
}
